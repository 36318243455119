import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LandingPage from '../views/Landing.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Beat Pressure',
    component: LandingPage,
    meta: { title: 'Beat Pressure' }
  },
  {
    path: '/play',
    name: 'Play Beat Pressure',
    component: () => import('../views/GameView.vue'),
    meta: { title: 'Play Beat Pressure' }

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // @ts-disable-next-line
  document.title = to.name as string;
  next();
});


export default router
