<template>

  <NavigationBar></NavigationBar>

  <router-view/>
</template>


<script setup lang="ts">
  import NavigationBar from '@/components/NavigationBar.vue';

  import '@/utils/logger.ts';

  import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'

  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = '0e27ea1ee16736b882098e295adbb87c'

  // 2. Set chains
  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }

  const moonbeam = {
  chainId: 1284, // Or 1287 for Moonbase Alpha
  name: 'Moonbeam',
  currency: 'GLMR',
  explorerUrl: 'https://moonscan.io', // Moonbeam block explorer
  rpcUrl: 'https://rpc.api.moonbeam.network' // Or 'https://rpc.testnet.moonbeam.network' for the testnet
  }

  const polygon = {
    chainId: 137,
    name: 'Polygon',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-rpc.com'
  }

  // 3. Create your application's metadata object
  const metadata = {
    name: 'Beat Pressure',
    description: 'My Website description',
    url: 'https://beatpressure.io', // url must match your domain & subdomain
    // icons: ['https://avatars.mywebsite.com/']
  }

  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    // rpcUrl: '...', // used for the Coinbase SDK
    // defaultChainId: 1, // used for the Coinbase SDK
  })

  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    chains: [mainnet, moonbeam, polygon],
    projectId,
  })


</script>

<style>
@font-face {
  font-family: 'Cyber';
  src: url('@/assets/fonts/Cyber.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dong';
  src: url('@/assets/fonts/Coin DingDong.ttf');
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

html {
    background-image: radial-gradient(circle, #212121 0%, #000000 100%);
    background-size: cover;
}
body {
  margin: 0;
}
/*
html::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: radial-gradient(circle, #474a51 0%, #000000 100%);
}*/



nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>