<template>
  <div class="landing-page">
    <header class="header">
      <img src="@/assets/logos/MusicProtocolLogo.png" alt="Music Protocol Logo" class="music-protocol-logo" />
      <h2>X</h2>
      <img src="@/assets/logos/BP_Text.png" alt="Beat Pressure Title" class="game-title" />
    </header>

    <section class="content">
      <h1>Join the Beat!</h1>
      <br>
      <p>Earn an airdrop badge by playing through a limited-time selection of levels in our latest rhythm game.</p>
      <br><br>
      <button class="cta-button" @click="this.$router.push('/play')">Play Now</button>
    </section>
    <footer class="footer">
      <img src="@/assets/logos/MusicProtocolIcon.png" alt="Music Protocol Icon" class="footer-logo" />
      <p>&copy; 2024 Music Protocol. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'LandingPage'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes backgroundMove {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  color: #fff;
  background: linear-gradient(135deg, #121212, #1d1d1d);
  background-size: 200% 200%;
  animation: backgroundMove 15s linear infinite;
  width: 100vw;
  height: calc(100vh - 50px);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  animation: fadeIn 2.5s ease-in;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.logo {
  max-width: 200px;
  margin-bottom: 10px;
}

.game-title, .music-protocol-logo {
  max-width: 300px;
  margin: 0px 0;
  filter: drop-shadow(2px 2px 10px rgba(0, 255, 255, 0.7)) drop-shadow(-2px -2px 10px rgba(255, 0, 255, 0.7));
}

h1, h2 {
  font-size: 40px;
  margin: 10px 0;
  animation: fadeIn 1.5s ease-in;
  text-shadow: 2px 2px 10px rgba(0, 255, 255, 0.7), -2px -2px 10px rgba(255, 0, 255, 0.7);
}

p {
  margin: 10px 0;
  font-size: 1.2em;
  animation: fadeIn 1.5s ease-in;
}

.cta-button {
  padding: 15px 30px;
  background: linear-gradient(45deg, #00d2ff, #ff4081);
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 20px rgba(0, 210, 255, 0.5), 0 0 30px rgba(255, 64, 129, 0.5);
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 210, 255, 0.5), rgba(255, 64, 129, 0.5));
  transition: transform 0.5s ease;
  z-index: 0;
}

.cta-button:hover::before {
  transform: translateX(100%);
}

.cta-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 30px rgba(0, 210, 255, 0.7), 0 0 40px rgba(255, 64, 129, 0.7);
}

.cta-button > * {
  position: relative;
  z-index: 1;
}


.footer {
  margin-top: 20px;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1.5s ease-in;
  padding-bottom: 20px;
}

.footer-logo {
  max-width: 50px;
  margin-bottom: 10px;
}
</style>
